
import React from "react"
import "../css/ImgInvitatie.css"
import ceas from "../images/ceas.png"

import dir from "../images/dir.png"

import table from "../images/table.png"

import biserica from "../images/des-biserica.png"


const ImgInvitatie =()=> {
    return (

   
        <div class="image-gol">



        <div class="dir-img">
          <img src={dir}/>
          <div class="dir-text">
            <h5>
            Dagenham
            </h5>
          </div>
          <div class="dir-text2">
            <h5>
              London
            </h5>
          </div>
        </div>

        <div class="clock-img animate__animated animate__pulse animate__delay-4s animate__infinite">
          <img src={ceas} />
          <div class="clock-text">
            <h5>
              5:00 <br/> p.m
            </h5>
          </div>
        </div>

       
        

        <div
          class="table-img animate__animated animate__delay-5s animate__swing animate__repeat-3 animate__slowe">
          <img src={table} />
          <div class="table-text">
            <h6 style={{color: "white"}}>
              Restaurant
            </h6>
          </div>
          <div class="table-text2">
            <h4 style={{color: "white"}}>
              ”Gold's”
            </h4>
          </div>
        </div>


     
     
       
      </div>

    )
}

export default ImgInvitatie;