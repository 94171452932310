import restaurant from "./images/r.jpg";

import img_card from "./images/picioruse.jpg";
import imgheadermb from "./images/fon3.jpg";
import imgheader from "./images/fon3.jpg";
import imgheadermiini from "./images/12345.jpg";
import imgheadermiinimb from "./images/123.jpg";
import logo from './images/clipart1.png'



const data = {
    introData: [{
        copilul: "Dominic Achim",
        familia: "the Rotaru family",
        logo: logo,
        tata: "Ionuț",
        mama: "Ioana",
        data: "June 18, 2023",
        data_confirmare: "June 8, 2023",
        savedata: "~ Invites you! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "ioanalenta9@gmail.com", 
       tel: "+447716247157",
       phone: "tel:+447716247157",
       viber: "viber://chat?number=%2Bxx",
       whatsapp: "https://wa.me/+447716247157",
       messenger: "https://www.messenger.com/t/ioana.lenta.7",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Event Party",
            localul: "Restaurant",
            name: "Gold's",
            data: "June 18, 2023, Sunday, 5:00 p.m",
            adress: "Dagenham, RM10 8TX",
            harta: "https://goo.gl/maps/wXhfxVK9Eccd7Lbg9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9924.100171197904!2d0.1617496!3d51.5494398!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a51982bc3c4b%3A0x8b70ebe16bd2939d!2zR29sZOKAmXMgR3lt!5e0!3m2!1sen!2s!4v1683871298960!5m2!1sen!2s" 
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "A child is a gift from God, a reason to believe in miracles, it is simply happiness on Earth.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Hi!",
            message: "If you have made it here, it means we care about you and we would love it if you would join us on such a special day!",
        }
    ],

}

export default data;