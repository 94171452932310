
import React from "react"
import ImgInvitatie from "./ImgInvitatie";
import mikey from '../images/clipart1.png'
import "../css/ImgInvitatie.css"


const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>
                Invitation</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <div className="text">
                            <h5>My name is,</h5>
                            <h4>Dominic</h4>
                            <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                                <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                </svg>
                                <br />
                            </h5>
                            <h5>
                                I think you know that I was born     <br />

                                I even saw some of you,     <br />

                                But I propose something       <br />

                                A party in my honor     <br />

                                Together with the parents </h5>

                            <h4>Ioana & Ionuț</h4>
                            <h5>and godparents</h5>
                            <h4>Lauren & Mihai</h4>                               <h5>
                                we invite you to party <br />
                                in songs and good cheer.
                            </h5>


                            <img id="mikey_img" src={mikey} />

                            <h4 class="m-2">Sunday, June 18, 2023</h4>
                        </div>



                        <ImgInvitatie />






                        <h4 class="card-footer">We look forward to seeing you!</h4>

                        <a href="#forms" className="btn btn-primary">Confirm Attendance</a>

                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;