import React, { Component } from "react";
import '../css/Header.css'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Language } from "@mui/icons-material";
import lang_ro from "../images/rom.jpg"
import lang_en from "../images/en.png"

export default class Header extends Component {
    constructor () {
        super();
        this.state={
           swow: true,
        };

    }
    render () {
        return (
        
<nav class="navbar navbar-expand-lg navbar-dark fixed-top">
  <div className="container-fluid px-5">
 {/**   <img id="logo" src={this.props.logo}/>
  <a className="navbar-brand" id="logo-name" href="#">
      {this.props.familia}</a>*/} 

      <Language/>
    <div className="mx-3">
    
    <a className="lang" href="https://dominic-achim.invitatii-web.ro/"><img className="lang_img mx-1" src={lang_ro}/><b>ro</b></a> 
    </div>
    <div>
    
    <a className="lang" href="#"><img className="lang_img mx-1" src={lang_en}/><b>en</b></a> 
    </div>
    <button className="navbar-toggler border text-dark border-dark" 
    onClick={()=>{this.setState({show: !this.state.show})}}>
      {this.state.show ?  <CloseIcon/> : <MenuIcon/>}
    </button>
    
    <div className={this.state.show ? 'collapse navbar-collapse  active bg-white text-center'  : 'collapse navbar-collapse text-center'}>
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <a className="nav-link text-dark"  href="#blog">Dominic</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-dark"  href="#invitatie">Invitation</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-dark" href="#geolocal">Where and When?</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-dark" href="#forms">Confirmation</a>
        </li>
      </ul>
    </div>
  </div>
</nav> 
     
        )

 }}